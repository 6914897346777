import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyClIa63yCjiIuTtiBF4Dn99gV9-VM9u2EU",
  authDomain: "fireside-training-limited.firebaseapp.com",
  databaseURL:
    "https://fireside-training-limited-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "fireside-training-limited",
  storageBucket: "fireside-training-limited.appspot.com",
  messagingSenderId: "123853656079",
  appId: "1:123853656079:web:9da76abe637a86c997137a",
  measurementId: "G-XXN5Q82MED",
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "FIRESIDE TRAINING LIMITED"; //公司名稱
      },
      get regFormDomain() {
        return "https://firesidetraining.xyz/";
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = "#e8f2fa";

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = "#000";

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
